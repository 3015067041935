





























































import { Vue, Component } from "vue-property-decorator";
import { api_partner, partner } from "@/http/partner";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
import { api_store, store } from "@/http/store";
@Component({
  components: {
    PageHeader,
  },
})
export default class extends Vue {
  validatorPhone = (rule: any, value: any, callback: any) => {
    if (value === "") {
      callback(new Error("手机号不能为空"));
    } else if (
      !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
        value
      )
    ) {
      callback(new Error("手机号格式错误"));
    } else {
      callback();
    }
  };
  validatorPass = (rule: any, value: any, callback: any) => {
    if (rule.show) {
      if (value === "") {
        callback(new Error("不能为空"));
      } else if (
        !/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[.~!@#$%^&*])[\da-zA-Z.~!@#$%^&*]{8,16}$/.test(
          value
        )
      ) {
        callback(new Error("密码必须包含数字、字母、特殊字符且8-16位字符"));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  validatorConfirmPass = (rule: any, value: any, callback: any) => {
    if (rule.show) {
      if (value === "") {
        callback(new Error("不能为空"));
      } else if (
        !/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[.~!@#$%^&*])[\da-zA-Z.~!@#$%^&*]{8,16}$/.test(
          value
        )
      ) {
        callback(
          new Error("密码必须包含数字、大小写字母、特殊字符且8-16位字符")
        );
      } else if (value !== this.info.password) {
        callback(new Error("密码不一致"));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  roleList: any = [];
  info: any = {
    username: "",
    account: "",
    password: "",
    confirm_password: "",
    status: 1,
    store: "",
    role: 8,
  };

  rules = {
    username: [{ required: true, message: "请输入至少2位数用户名", min: 2 }],
    account: [{ required: true, message: "请填写" }],
    // phone: [{ required: true, validator: this.validatorPhone }],
    password: [{ required: true, validator: this.validatorPass, show: true }],
    confirm_password: [
      { required: true, validator: this.validatorConfirmPass, show: true },
    ],
    store: [{ required: true, message: "请选择门店" }],
  };

  async submit() {
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    if (this.id) {
      await api_partner.edit(this.info);
    } else {
      let info = { ...this.info };
      await api_partner.create(info);
    }
    // this.$message.success("修改成功");
    this.$router.go(-1);
  }

  async get_info() {
    this.rules.password[0].required = false;
    this.rules.password[0].show = false;
    this.rules.confirm_password[0].required = false;
    this.rules.confirm_password[0].show = false;
    this.info = await api_partner.get_info(this.id as number);
  }

  get id(): undefined | number {
    return this.$route.query.id as any;
  }
  // 获取门店列表
  storeList: any[] = [];
  async get_store_list() {
    const res = await api_store.get_list({ page_size: 10000 });
    const list: any[] = [];
    res.results.forEach((item: any) => {
      (item.value = item.id), (item.label = item.name);
    });
    this.storeList = list.concat(res.results);
  }

  created() {
    this.get_store_list();
    if (this.id === undefined) return;
    this.get_info();
  }
}
