import { Http } from "@/http";
import { Id, res_list, req_list } from "@/types/global";

export interface add_partner {
  username: string;
  account: string;
  password?: string;
  confirm_password?: string;
  status: number | string;
  store: string;
  role: string;
}
export interface partner extends add_partner {
  id: Id;
  create_time: string;
  update_time: string;
}

class HttpPartner extends Http {
  get_list = (data: req_list) => {
    return this.get<res_list<partner>>({ ...data });
  };

  get_info = (id: number) => {
    return this.get<partner>({}, `/admin/partner/${id}/`);
  };

  create = (data: add_partner) => {
    return this.post(data);
  };

  edit = ({ id, ...data }: partner) => {
    return this.patch(data, `/admin/partner/${id}/`);
  };

  del_one = (id: number) => {
    return this.delete(id);
  };
}

export const api_partner = new HttpPartner("/admin/partner/");
